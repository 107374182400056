import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Privacy from "../components/Privacy";

let IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="privacy" />
    <Privacy />
  </Layout>
);

export default IndexPage;
