import React from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../utils/PageTitle";

export default function index() {
  return (
    <div>
      <section className={`${styles.section} page-section-top`}>
        <div className={styles.container}>
          <PageTitle title="privacy policy" />
          <div className={styles.privacy}>
            <p>
              Vectr Ventures Limited (Vectr) is committed to protecting and
              respecting your privacy.
            </p>
            <p>
              This policy, together with our Terms of Use
              <a href="www.vectr.co/terms-of-use/">
                (www.vectr.co/terms-of-use/)
              </a>
              and any other documents referred to in it, sets out the basis on
              which any personal data we collect from you, or that you provide
              to us, will be processed by us. Please read the following
              carefully to understand our views and practices regarding your
              personal data and how we will treat it.
            </p>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>INFORMATION COLLECTION</h5>
              <ul className={styles.list}>
                <li className={styles.item}>
                  In order to provide a better service to you, we may collect
                  and process the following data about you.
                </li>
                <li className={styles.item}>
                  We only retain personal data for so long as it is necessary.
                  Data may be archived as long as the purpose for which the data
                  was used still exists.
                </li>
              </ul>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>
                USES MADE OF THE INFORMATION
              </h5>
              <ul className={styles.list}>
                <li className={styles.item}>
                  The purposes for which information may be used by us in and
                  outside the Hong Kong Special Administrative Region include:
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      Ensuring that content from our site is presented in the
                      most effective manner for you and for your computer;
                    </li>
                    <li className={styles.item}>
                      Providing you with alerts, newsletter, education materials
                      or information that you requested or signed up to;
                    </li>
                    <li className={styles.item}>
                      Carrying out our obligations arising from any contracts
                      entered into between you and us;
                    </li>
                    <li className={styles.item}>
                      Allowing you to participate in interactive features of our
                      service, when you choose to do so;
                    </li>
                    <li className={styles.item}>
                      Designing and conducting surveys/questionnaires for client
                      profiling/segmentation, statistical analysis, improving
                      and furthering the provision our products and services;
                    </li>
                    <li className={styles.item}>
                      Complying with laws and regulations applicable to us or
                      any of our affiliates in or outside the Hong Kong Special
                      Administrative Region;
                    </li>
                    <li className={styles.item}>
                      Legal proceedings, including collecting overdue amounts
                      and seeking professional advices;
                    </li>
                    <li className={styles.item}>
                      Researching, designing and launching services or products
                      including seminars/events/forums;
                    </li>
                    <li className={styles.item}>
                      Promoting and marketing services and products subject to
                      your exercise of the opt-out right (please see further
                      details in clause 2.2 below); or
                    </li>
                    <li className={styles.item}>
                      Purposes directly related or incidental to the above.
                    </li>
                  </ul>
                </li>
                <li className={styles.item}>
                  We intend to use your data in direct marketing and we require
                  your consent (which includes an indication of no objection)
                  for that purpose. In this connection, please note that:
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      Your name, contact details (including address, contact
                      number, email address), products and services information,
                      transaction pattern and behaviour, background and
                      demographic data held by us from time to time may be used
                      by us in direct marketing;
                    </li>
                    <li className={styles.item}>
                      The following classes of services, products and subjects
                      may be marketed in direct marketing:
                      <ul className={styles.list}>
                        <li className={styles.item}>
                          Services and products related to our site and/or our
                          affiliates (including marketing affiliates programs we
                          are a part of);
                        </li>
                        <li className={styles.item}>
                          Reward, loyalty or privileges programmes, promotional
                          offers and related services; and
                        </li>
                        <li className={styles.item}>
                          Invitations to events such as
                          seminars/webinars/tele-seminars, conferences, live
                          programs or events.
                        </li>
                      </ul>
                    </li>
                    <li className={styles.item}>
                      We may conduct direct marketing via fax, email, direct
                      mail, telephone and other means of communication or send
                      e-newsletters to you. You may choose not to receive
                      promotional materials, by simply telling us (see below for
                      contact details), and we will cease to do so, without
                      charge.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>
                DISCLOSURE OF YOUR INFORMATION
              </h5>
              <p>
                {" "}
                We will keep the personal data we hold confidential but may
                provide information to:
              </p>
              <ul className={styles.list}>
                <li className={styles.item}>
                  Personnel, agents, advisers, auditors, contractors, financial
                  institutions, and service providers in connection with our
                  operations or services;
                </li>
                <li className={styles.item}>
                  Our overseas offices, affiliates, business partners and
                  counterparts (if any);
                </li>
                <li className={styles.item}>
                  Persons under a duty of confidentiality to us;
                </li>
                <li className={styles.item}>
                  Persons to whom we are required to make disclosure under
                  applicable laws and regulations in or outside the Hong Kong
                  Special Administrative Region; or
                </li>
                <li className={styles.item}>
                  Actual or proposed transferees or participants of our services
                  in or outside the Hong Kong Special Administrative Region.
                </li>
                <li className={styles.item}>
                  Security. Unfortunately, the transmission of information via
                  the internet is not completely secure. Although we will do our
                  best to protect your personal data, we cannot guarantee the
                  security of your data transmitted to our site; any
                  transmission is at your own risk. Once we have received your
                  information, we will use strict procedures and security
                  features to try to prevent unauthorised access.
                </li>
                <li className={styles.item}>Changes to our privacy policy</li>
                <li className={styles.item}>Data transfers</li>
              </ul>
              <p>
                We may hold your data on servers in Hong Kong and any other
                territories as we see fit from time to time. We may also
                transfer your data to our overseas offices or to any people
                listed at clause 3.1 above, who may be located in or outside of
                the Hong Kong Special Administrative Region.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>YOUR CONSENT AND RIGHTS</h5>
              <ul className={styles.list}>
                <li className={styles.item}>
                  By using our service, making an application or visiting our
                  website, you consent to the collection and use of your
                  information and other activities as outlined in this policy.
                </li>
                <li className={styles.item}>
                  Under the Hong Kong Personal Data (Privacy) Ordinance (the
                  “Ordinance”), individuals have the right:
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      To check whether we hold personal data about you and to
                      access such data;
                    </li>
                    <li className={styles.item}>
                      To require us to correct as soon as reasonably practicable
                      any data relating to you which is inaccurate;
                    </li>
                    <li className={styles.item}>
                      To ascertain our policies and practices in relation to
                      personal data and the kind of personal data held by us;
                      and
                    </li>
                    <li className={styles.item}>
                      To object to the use of your personal data for marketing
                      purposes and we shall not use your personal data for
                      marketing purposes after you communicate your objection to
                      us.
                    </li>
                  </ul>
                </li>
                <li className={styles.item}>
                  You may exercise your opt-out right by notifying us if you
                  wish to object to the use of your personal data for direct
                  marketing purposes. Please email us requests for such
                  objections, access to data, correction of data, information
                  regarding policies and practices and kinds of data held,
                  questions or complaints.
                </li>
                <li className={styles.item}>
                  In accordance with the terms of the Ordinance, we have the
                  right to and may charge a reasonable fee for processing any
                  data access request.
                </li>
                <li className={styles.item}>Governing law and jurisdiction</li>

                <p>
                  Nothing in this Privacy Policy shall limit the rights of the
                  data subject under the Ordinance. This Privacy Policy shall be
                  governed by the laws of the Hong Kong Special Administrative
                  Region. You agree to submit to the exclusive jurisdiction of
                  the Hong Kong courts.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
